var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { SettingTemplate, AntdForm, GUIDE_NAMES, EverydayTimeSetter, TimeRangeInputs, CardWithTabs, FloatingButtonGroup, HookFormInputs, TextComponent, AntPrimaryGhostButton, BigTitleModal, ClickableText, AdditionalCover, DepositStatusLabel, SortableTables, DrawerComponent, DrawerFooter, syncNaverCalendar, useEntireLoader } from 'renderer/components';
import Col from 'antd/lib/col';
import Form from 'antd/lib/form';
import Row from 'antd/lib/row';
import { StoreAccountQuery, StoreQuery } from 'renderer/queries';
import notification from 'antd/lib/notification';
import { Lodash, useWindowSize, isEqual, openWindow, LambdaClient, times } from 'renderer/utils';
import styled from 'styled-components';
import { COLORS } from 'renderer/styles';
import { useForm } from 'react-hook-form';
import { Layout } from 'common-ui';
import { BANKS } from 'renderer/constants';
import dayjs from 'dayjs';
export var CalendarSetting = function (props) {
    var _a;
    var _b = React.useState(((_a = props.tab) === null || _a === void 0 ? void 0 : _a.tab1) || 'calendar'), activeTab = _b[0], setActiveTab = _b[1];
    var handleTabChange = function (tab) {
        setActiveTab(tab);
    };
    var tabs = [
        { tab: '예약캘린더', key: 'calendar' },
        { tab: '예약금', key: 'deposit' }
    ];
    return (React.createElement(StyledSettingTemplate, { title: "\uC608\uC57D\uC124\uC815", description: "", guideName: GUIDE_NAMES.예약캘린더설정, body: React.createElement(StyledCardTabs, { preventBodyScroll: true, activeTabKey: activeTab, onTabChange: handleTabChange, tabList: tabs, children: React.createElement(Wrapper, { "$padding": 0, "$height": props.height - 80 }, activeTab === 'calendar' ? React.createElement(CalendarDisplaySettings, __assign({}, props)) : React.createElement(DepositSetting, __assign({}, props))) }) }));
};
var StyledSettingTemplate = styled(function (props) { return React.createElement(SettingTemplate, __assign({}, props)); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  & > .ant-row {\n    &:first-child {\n      padding-left: 15px;\n      padding-top: 12px;\n      padding-bottom: 12px;\n      border-bottom: 1px solid ", ";\n    }\n  }\n"], ["\n  & > .ant-row {\n    &:first-child {\n      padding-left: 15px;\n      padding-top: 12px;\n      padding-bottom: 12px;\n      border-bottom: 1px solid ", ";\n    }\n  }\n"])), COLORS.gray6);
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: ", "px;\n  padding: ", "px;\n\n  width: 100%;\n"], ["\n  height: ", "px;\n  padding: ", "px;\n\n  width: 100%;\n"])), function (props) { return props.$height; }, function (props) { return props.$padding; });
var DepositSetting = function (props) {
    var _a;
    var _b = React.useState(((_a = props.tab) === null || _a === void 0 ? void 0 : _a.tab2) || 'deposit'), activeTab = _b[0], setActiveTab = _b[1];
    var handleTabChange = function (tab) {
        setActiveTab(tab);
    };
    var tabs = [
        { tab: '예약금설정', key: 'deposit' },
        { tab: '문자 #{변수}', key: 'messageVariables' },
        { tab: '입금 자동확인', key: 'depositAutoConfirm' }
        // { tab: '기타', key: 'etc' }
    ];
    return (React.createElement(StyledCardTabs, { activeTabKey: activeTab, onTabChange: handleTabChange, tabList: tabs, children: React.createElement(Wrapper, { "$padding": 20, "$height": props.height - 130 }, activeTab === 'deposit' ? (React.createElement(DepositAmountSetting, { store: props.store })) : activeTab === 'messageVariables' ? (React.createElement(DepositMessageVariableSetting, { store: props.store })) : (React.createElement(DepositAutoConfirmSetting, { store: props.store }))) }));
};
var DepositAutoConfirmSetting = function (props) {
    var store = props.store;
    var operationPreference = store.operationPreference;
    var depositConfirmPolicy = operationPreference.depositConfirmPolicy;
    var defaultValues = {
        completed: true,
        over: depositConfirmPolicy === null || depositConfirmPolicy === void 0 ? void 0 : depositConfirmPolicy.over,
        under: depositConfirmPolicy === null || depositConfirmPolicy === void 0 ? void 0 : depositConfirmPolicy.under
    };
    var _a = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _a[1];
    var form = useForm({ defaultValues: defaultValues });
    var values = form.watch();
    var update = StoreQuery.useUpdateStore();
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var newPreference, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    newPreference = __assign(__assign({}, store.preference), { operation: __assign(__assign(__assign({}, store.operationPreference), (_a = store.preference) === null || _a === void 0 ? void 0 : _a.operation), { depositConfirmPolicy: values }) });
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, update.mutateAsync({
                            id: props.store.id,
                            preference: newPreference
                        })];
                case 2:
                    _b.sent();
                    notification.info({ message: '입금 자동확인 설정이 변경되었습니다.' });
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _b.sent();
                    notification.info({ message: '입금 자동확인 설정이 변경 중 에러가 발생했습니다.' });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    var checkboxes = ['completed', 'over', 'under'];
    React.useEffect(function () {
        form.reset(defaultValues);
    }, [props.store]);
    return (React.createElement(DepositAmountSettingForm, null,
        React.createElement(AdditionalCover, { store: store, type: "deposit" }),
        React.createElement(AccountRegisterInput, { store: store }),
        React.createElement(TextComponent, { children: "\uC790\uB3D9\uC785\uAE08 \uD655\uC778\uC2DC \uC608\uC57D\uD655\uC815\uC73C\uB85C \uBCC0\uACBD\uB418\uB294 \uC608\uC57D\uAE08\uC0C1\uD0DC", type: "subtitle3" }),
        checkboxes.map(function (check) {
            return (React.createElement(HookFormInputs.CheckboxInput, { key: check, inputProps: {
                    children: React.createElement(DepositStatusLabel, { status: check }),
                    disabled: check === 'completed',
                    checked: values[check]
                }, controlProps: {
                    name: check,
                    control: form.control
                } }));
        }),
        React.createElement(TextComponent, { children: "- \uACC4\uC88C\uBC88\uD638 \uC778\uC99D\uC808\uCC28\uC744 \uC644\uB8CC\uD574\uC57C \uC124\uC815\uD560 \uC218 \uC788\uC2B5\uB2C8\uB2E4.", type: "caption1", color: COLORS.gray3 }),
        React.createElement(FloatingButtonGroup, { visible: form.formState.isDirty, confirmButtonProps: {
                onClick: function () {
                    onSubmit();
                }
            }, cancelButtonProps: {
                onClick: function () {
                    form.reset(defaultValues);
                    forceUpdate();
                }
            } })));
};
var AccountRegisterInput = function (props) {
    var _a, _b, _c, _d;
    var account = StoreAccountQuery.useStoreAccount({ storeId: (_a = props.store) === null || _a === void 0 ? void 0 : _a.id }, { enabled: !!((_b = props.store) === null || _b === void 0 ? void 0 : _b.id) });
    var _e = React.useState(false), open = _e[0], setOpen = _e[1];
    var textObject = {
        pending: {
            color: COLORS.warning,
            children: '계좌 확인 중'
        },
        success: {
            color: COLORS.success,
            children: '인증완료'
        },
        failure: {
            color: COLORS.danger,
            children: '인증실패'
        },
        null: {
            color: COLORS.warning,
            children: '미등록'
        }
    };
    var messages = [
        '- 입금자명과 예약의 #{고객명}이 동일할 경우에만 예약에 자동연결됩니다.',
        '- 예약금상태가 ‘입금대기’인 예약만 자동연결할 수 있습니다.',
        '- 예약금상태가 ‘입금대기’인 상태의 동명이인이 있을 경우, 어떤 예약에도 연결되지 않습니다.',
        '- 계좌등록 후 인증완료까지 최대 5분이 소요될 수 있습니다.',
        '- 계좌 인증 실패, 계좌 수정/삭제 관련 문의는 채널톡 상담을 이용해 주세요.',
        '- 은행점검시간(새벽 12시~1시)동안은 입금내역을 불러올 수 없으니 이용에 참고해주세요.',
        '- 계좌등록 후 인증이 완료되면 [예약금 입금내역] 페이지에서 입금내역을 조회할 수 있습니다.',
        '- 계좌 인증완료일 00시 이후의 입금내역을 불러옵니다.',
        '- 계좌인증이 완료되면 문자#{변수}의 계좌정보도 동일하게 변경됩니다.'
    ];
    return (React.createElement("div", null,
        React.createElement(TextComponent, { children: "#{\uACC4\uC88C\uBC88\uD638}\uC778\uC99D", type: "subtitle3", marginBottom: 5 }),
        React.createElement(Layout.FluidInline, { align: "center", gutter: 8 },
            React.createElement(AccountInput, null,
                (account === null || account === void 0 ? void 0 : account.data) && (React.createElement(TextComponent, { marginRight: 5, children: "".concat(account.data.accountNumber, " ").concat((_c = account.data) === null || _c === void 0 ? void 0 : _c.bankName) })),
                React.createElement(TextComponent, __assign({}, textObject[((_d = account === null || account === void 0 ? void 0 : account.data) === null || _d === void 0 ? void 0 : _d.status) || 'null'], { type: "caption1" }))),
            React.createElement(AntPrimaryGhostButton, { children: "\uC0C8 \uACC4\uC88C\uB4F1\uB85D", onClick: function () {
                    var _a, _b;
                    if (((_a = account === null || account === void 0 ? void 0 : account.data) === null || _a === void 0 ? void 0 : _a.status) === 'pending') {
                        return notification.info({ message: '계좌인증 중에는 새로 등록할 수 없습니다.' });
                    }
                    if (((_b = account === null || account === void 0 ? void 0 : account.data) === null || _b === void 0 ? void 0 : _b.status) === 'success') {
                        return notification.info({ message: '계좌정보 변경을 원하시면 채팅상담으로 문의해주세요.' });
                    }
                    var now = dayjs();
                    if (now.hour() === 0) {
                        return notification.info({ message: '0시~1시는 은행점검으로 계좌등록을 할 수 없습니다.' });
                    }
                    setOpen(true);
                } }),
            React.createElement(AccountRegisterModal, { store: props.store, open: open, onClose: function () {
                    setOpen(false);
                } })),
        messages.map(function (value, index) {
            var isLast = index === messages.length - 1;
            var isFirst = index === 0;
            return (React.createElement(TextComponent, { key: value, marginTop: isFirst ? 13 : 0, marginBottom: isLast ? 25 : 0, children: value, color: COLORS.gray3, type: "caption1" }));
        })));
};
var AccountInput = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  min-width: 230px;\n  height: 32px;\n  border-radius: 2px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding-left: 18px;\n  padding-right: 18px;\n"], ["\n  min-width: 230px;\n  height: 32px;\n  border-radius: 2px;\n  background-color: ", ";\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  padding-left: 18px;\n  padding-right: 18px;\n"])), COLORS.gray7);
var NOTION_LINK_BY_BANK = {
    '13': 'https://help.thinkofyou.kr/f56e0532-3937-4269-bdd0-010b4751b00d',
    '04': 'https://help.thinkofyou.kr/00a76dc8-0a7d-4db1-803b-5f130eaeb45b',
    '20': 'https://help.thinkofyou.kr/8a5f6924-e63d-4114-b163-a7494e84f862',
    '26': 'https://help.thinkofyou.kr/51a155b8-e0cb-4b92-81ed-f965e860a0ce',
    '03': 'https://help.thinkofyou.kr/b98469ad-7c9b-4475-a592-f43f18ff7177',
    '81': 'https://help.thinkofyou.kr/b5ac84cb-4651-4fa9-a87e-07322aa3fba7',
    '71': 'https://help.thinkofyou.kr/1802056c-b0a1-4010-9fe0-68f9fd4e50bb',
    '23': 'https://help.thinkofyou.kr/72c54275-f055-4d6e-86da-68999b27ab4b',
    '31': 'https://help.thinkofyou.kr/2fc609cc-3f35-4b37-a7b3-6d279c44cae0',
    '28': 'https://help.thinkofyou.kr/3fb3d2c9-42ea-415b-9a35-f740047962e3',
    '32': 'https://help.thinkofyou.kr/6e7b0111-2b9b-4d2f-8e73-b5a6cc88b930',
    '39': 'https://help.thinkofyou.kr/7c7d914a-09c5-4721-b9e0-fa3e0d0a49ac',
    '34': 'https://help.thinkofyou.kr/c8d5e4da-aad6-4249-8250-c7c01b76742a',
    '37': 'https://help.thinkofyou.kr/f5ae0507-4add-4f16-b6bf-1e001b36067d',
    '45': 'https://help.thinkofyou.kr/c9cb3dee-0e97-401c-8f3a-d71eea4bb002',
    '89': 'https://help.thinkofyou.kr/57cb181b-480a-4f7a-b521-044293f55e65',
    '88': 'https://help.thinkofyou.kr/ab252722-5292-494b-a5be-e27d5aceda6a',
    '35': 'https://help.thinkofyou.kr/832f6d33-1c88-414c-a993-5ab654aa7e51',
    '48': 'https://help.thinkofyou.kr/33474a54-8cde-4759-8f15-48d13ae5e4b1' // 신협
};
var AccountRegisterModal = function (props) {
    var _a, _b, _c, _d, _e;
    var defaultValues = {
        bankCode: '04',
        storeId: (_a = props.store) === null || _a === void 0 ? void 0 : _a.id,
        ownerType: 'P',
        ownerName: '',
        identityNumber: '',
        accountNumber: '',
        accountPassword: '',
        webId: '',
        webPW: ''
    };
    var form = useForm({
        defaultValues: defaultValues,
        mode: 'onChange'
    });
    var fields = StoreAccountQuery.getRegisterAccountFields({ storeId: (_b = props.store) === null || _b === void 0 ? void 0 : _b.id }, { enabled: !!((_c = props.store) === null || _c === void 0 ? void 0 : _c.id) });
    var add = StoreAccountQuery.useAddAccount();
    var values = form.watch();
    var validateBankAvailability = function (bank, ownerType) {
        return !bank.comment.includes('신규등록안됨') && !bank[ownerType].fields.includes('조회불가');
    };
    var banks = React.useMemo(function () {
        var _a;
        return (_a = fields === null || fields === void 0 ? void 0 : fields.data) === null || _a === void 0 ? void 0 : _a.filter(function (item) {
            return validateBankAvailability(item, values.ownerType);
        }).map(function (_a) {
            var name = _a.name, code = _a.code;
            return {
                label: name,
                value: code
            };
        });
    }, [fields === null || fields === void 0 ? void 0 : fields.data, values.ownerType]);
    var bank = (_d = fields === null || fields === void 0 ? void 0 : fields.data) === null || _d === void 0 ? void 0 : _d.find(function (item) { return item.code === values.bankCode; });
    var dynamicFields = bank && bank[values.ownerType];
    var isValidate = form.formState.isValid;
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, alreadyRegistered_1, rest, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, 3, 4]);
                    _a = values, alreadyRegistered_1 = _a.alreadyRegistered, rest = __rest(_a, ["alreadyRegistered"]);
                    return [4 /*yield*/, add.mutateAsync(__assign(__assign({}, rest), { bankName: bank.name }))];
                case 1:
                    _b.sent();
                    notification.info({ message: '계좌등록이 완료되었습니다.' });
                    return [3 /*break*/, 4];
                case 2:
                    error_2 = _b.sent();
                    notification.info({ message: error_2.message || '계좌등록 중 에러가 발생했습니다.' });
                    return [3 /*break*/, 4];
                case 3:
                    form.reset(defaultValues);
                    props.onClose();
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    var bankCode = form.watch('bankCode');
    var alreadyRegistered = form.watch('alreadyRegistered');
    React.useEffect(function () {
        if (!!values.ownerType && (banks === null || banks === void 0 ? void 0 : banks.findIndex(function (item) { return item.value === bankCode; })) === -1) {
            form.setValue('bankCode', defaultValues.bankCode);
        }
    }, [values.ownerType, banks, bankCode, form, defaultValues.bankCode]);
    return (React.createElement(BigTitleModal, { open: props.open, width: 360, centered: true, title: "\uC785\uAE08\uACC4\uC88C \uB4F1\uB85D", children: React.createElement(React.Fragment, null,
            React.createElement(AccountForm, null,
                React.createElement(TextComponent, { children: "\uC608\uC57D\uAE08 \uC790\uB3D9\uC785\uAE08 \uC11C\uBE44\uC2A4 \uC774\uC6A9\uC5D0 \uD544\uC694\uD55C \uACC4\uC88C\uBC88\uD638\uB97C \uB4F1\uB85D\uD574\uC8FC\uC138\uC694.", type: "subtitle2", marginBottom: 0 }),
                React.createElement(TextComponent, { marginBottom: 20, children: "\uBB38\uC790\uC790\uB3D9\uBC1C\uC1A1 \uC2DC \uC774\uC6A9\uB418\uB294 #{\uACC4\uC88C\uBC88\uD638} \uBCC0\uC218\uC5D0\uB3C4 \uB3D9\uC77C\uD55C \uACC4\uC88C\uBC88\uD638\uAC00 \uC0AC\uC6A9\uB429\uB2C8\uB2E4.", type: "caption1", color: COLORS.gray2 }),
                React.createElement(TextComponent, { marginBottom: 10, children: "1. \uC740\uD589\uC120\uD0DD", type: "subtitle2" }),
                React.createElement(HookFormInputs.SelectInput, { label: "\uACC4\uC88C \uC18C\uC720\uC8FC \uC885\uB958*", controlProps: {
                        name: "ownerType",
                        control: form.control
                    }, inputProps: {
                        placeholder: '예금주명을 입력해주세요.',
                        style: { width: '100%' },
                        options: [
                            { value: 'P', label: '개인' },
                            { value: 'C', label: '법인' }
                        ]
                    } }),
                React.createElement(HookFormInputs.SelectInput, { label: "\uC740\uD589\uBA85*", controlProps: {
                        name: "bankCode",
                        control: form.control,
                        rules: {
                            required: '은행 선택'
                        }
                    }, inputProps: {
                        placeholder: '은행을 선택해주세요.',
                        style: { width: '100%' },
                        // FIXME: 외환은행이 두개임
                        options: banks
                    } }),
                React.createElement(TextComponent, { marginTop: 10, marginBottom: 10, children: "2. \uBE60\uB978 \uACC4\uC88C \uC870\uD68C \uC11C\uBE44\uC2A4 \uB4F1\uB85D", type: "subtitle2" }),
                React.createElement(TextComponent, { marginBottom: 6, children: "\uB4F1\uB85D\uD558\uC2E4 \uACC4\uC88C\uC758 \uC740\uD589\uC5D0 [\uBE60\uB978\uACC4\uC88C \uC870\uD68C \uC11C\uBE44\uC2A4]\uAC00 \uAC00\uC785\uB418\uC5B4 \uC788\uC5B4\uC57C \uC774\uC6A9 \uAC00\uB2A5\uD569\uB2C8\uB2E4.", type: "caption1" }),
                React.createElement(ClickableText, { textAlign: "left", marginTop: 6, marginBottom: 10, onClick: function () {
                        openWindow(NOTION_LINK_BY_BANK[bankCode] || "https://help.thinkofyou.kr/aab427fa-4f83-4eaf-8c86-ef4174ee6dbb", '빠른계좌등록');
                    }, type: "caption1", children: "\uBE60\uB978 \uACC4\uC88C \uC870\uD68C \uC11C\uBE44\uC2A4 \uB4F1\uB85D\uBC29\uBC95 \uD655\uC778\uD558\uAE30 >", color: COLORS.primary1 }),
                React.createElement(HookFormInputs.CheckboxInput, { inputProps: {
                        children: (React.createElement(TextComponent, { marginBottom: 20, type: "caption1", children: "\uB4F1\uB85D\uD558\uB824\uB294 \uACC4\uC88C\uAC00 \uBE60\uB978 \uACC4\uC88C \uC11C\uBE44\uC2A4\uC5D0 \uAC00\uC785\uB418\uC5B4 \uC788\uC2B5\uB2C8\uB2E4." }))
                    }, controlProps: {
                        validate: function (value) {
                            if (value !== true) {
                                return 'Please, choose a value';
                            }
                            return true;
                        },
                        name: "alreadyRegistered",
                        control: form.control
                    } }),
                alreadyRegistered && (React.createElement(React.Fragment, null,
                    React.createElement(TextComponent, { marginBottom: 10, children: "3. \uACC4\uC88C\uC815\uBCF4 \uC785\uB825", type: "subtitle2" }),
                    React.createElement(HookFormInputs.Text, { label: "\uC608\uAE08\uC8FC\uBA85", controlProps: {
                            name: "ownerName",
                            control: form.control
                        }, inputProps: {
                            placeholder: '사용안함',
                            style: { width: '100%' }
                        } }), (_e = dynamicFields === null || dynamicFields === void 0 ? void 0 : dynamicFields.fields) === null || _e === void 0 ? void 0 :
                    _e.map(function (field, index) {
                        field = field.replace('0000000', '');
                        var name = index === 0
                            ? 'accountNumber'
                            : index === 1
                                ? 'accountPassword'
                                : index === 2
                                    ? 'identityNumber'
                                    : index === 3
                                        ? 'webId'
                                        : 'webPW';
                        var placeholder = name === 'accountNumber'
                            ? '계좌번호 입력  (-없이 숫자만 입력)'
                            : name === 'accountPassword'
                                ? '계좌비밀번호 4자리를 입력해주세요.'
                                : name === 'identityNumber'
                                    ? values.ownerType === 'P'
                                        ? 'YYMMDD (-없이 숫자만 입력)'
                                        : '사업자등록번호 입력 (-없이 숫자만 입력)'
                                    : "".concat(field, "\uB97C \uC785\uB825");
                        var rules = name === 'accountPassword'
                            ? {
                                maxLength: {
                                    value: 4,
                                    message: '계좌비밀번호 4자리를 입력해주세요.'
                                },
                                pattern: {
                                    value: /\d{4}/g,
                                    message: '계좌비밀번호 4자리를 입력해주세요.'
                                }
                            }
                            : name === 'accountNumber'
                                ? {
                                    maxLength: {
                                        value: 30,
                                        message: '올바른 계좌번호를 입력해주세요.'
                                    },
                                    pattern: {
                                        value: /\d{5,30}/,
                                        message: '올바른 계좌번호를 입력해주세요.'
                                    }
                                }
                                : name === 'identityNumber'
                                    ? values.ownerType === 'P'
                                        ? {
                                            maxLength: {
                                                value: 6,
                                                message: '올바른 생년월일(YYMMDD) 6자리를 입력해주세요.'
                                            },
                                            pattern: {
                                                value: /\d{6}/g,
                                                message: '생년월일(YYMMDD) 6자리를 입력해주세요.'
                                            }
                                        }
                                        : {
                                            maxLength: {
                                                value: 10,
                                                message: '올바른 사업자등록번호를 입력해주세요.'
                                            },
                                            pattern: {
                                                value: /\d{10}/g,
                                                message: '올바른 사업자등록번호를 입력해주세요.'
                                            }
                                        }
                                    : {};
                        return (React.createElement(HookFormInputs.Text, { key: field, label: "".concat(field, "*"), controlProps: {
                                name: name,
                                control: form.control,
                                rules: __assign({ required: "".concat(field, "\uB97C \uC785\uB825\uD574\uC8FC\uC138\uC694.") }, rules)
                            }, inputProps: {
                                placeholder: placeholder,
                                autoComplete: 'new-password',
                                style: { width: '100%' },
                                type: name === 'accountPassword' ? 'password' : 'text'
                            } }));
                    }))))), cancelText: "\uB2EB\uAE30", onCancel: props.onClose, onOk: onSubmit, okText: "\uACC4\uC88C \uB4F1\uB85D", okButtonProps: { disabled: !isValidate || !alreadyRegistered } }));
};
var AccountForm = styled.form(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 20px;\n  margin-bottom: 80px;\n"], ["\n  padding: 20px;\n  margin-bottom: 80px;\n"])));
var DepositAmountSetting = function (props) {
    var _a, _b;
    var store = props.store;
    var operationPreference = store.operationPreference;
    var segments = ((_a = store === null || store === void 0 ? void 0 : store.preference) === null || _a === void 0 ? void 0 : _a.customerFilters) || [];
    var defaultValues = {
        defaultStatus: operationPreference.defaultStatus || 'confirm',
        pendingCustomersSegmentFields: ((_b = operationPreference === null || operationPreference === void 0 ? void 0 : operationPreference.pendingCustomersSegmentFields) === null || _b === void 0 ? void 0 : _b.filter(function (item) {
            return segments.find(function (segment) { return segment.id === item; });
        })) || [],
        defaultDeposit: operationPreference.defaultDeposit || 0
    };
    var form = useForm({ defaultValues: defaultValues });
    var values = form.watch();
    var hasDeposit = Number.isInteger(values.defaultDeposit) && values.defaultDeposit !== 0;
    var update = StoreQuery.useUpdateStore();
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var newPreference, error_3;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    newPreference = __assign(__assign({}, store.preference), { operation: __assign(__assign(__assign({}, store.operationPreference), (_a = store.preference) === null || _a === void 0 ? void 0 : _a.operation), { defaultStatus: values.defaultStatus || 'confirm', pendingCustomersSegmentFields: values.pendingCustomersSegmentFields || [], defaultDeposit: values.defaultDeposit || 0 }) });
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, update.mutateAsync({
                            id: props.store.id,
                            preference: newPreference
                        })];
                case 2:
                    _b.sent();
                    notification.info({ message: '예약금 설정이 변경되었습니다.' });
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _b.sent();
                    notification.error({ message: '예약금 설정이 번경 중 오류가 발생했습니다.' });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    React.useEffect(function () {
        form.reset(defaultValues);
    }, [store.preference]);
    return (React.createElement(DepositAmountSettingForm, null,
        React.createElement(Layout.FluidInline, { align: "center", gutter: 8 },
            React.createElement("div", null,
                React.createElement(HookFormInputs.NumberInput, { label: "\uC608\uC57D\uB4F1\uB85D\uC2DC \uC608\uC57D\uAE08*", controlProps: {
                        name: "defaultDeposit",
                        control: form.control,
                        rules: {
                            required: { value: true, message: '가격을 입력해주세요.' },
                            min: { value: 0, message: '가격은 0원 이상이어야합니다.' }
                        }
                    }, inputProps: {
                        suffix: '원'
                    } })),
            React.createElement(Layout.Inline, { align: "center", gutter: 4, style: { marginTop: '15px' } },
                React.createElement(HookFormInputs.SwitchInput, { inputProps: {
                        checked: hasDeposit,
                        onChange: function (ev) {
                            var checked = ev.target.checked;
                            form.setValue('defaultDeposit', checked ? 20000 : 0, { shouldDirty: true });
                        }
                    }, controlProps: {
                        name: "hasDeposit",
                        control: form.control
                    } }),
                React.createElement(TextComponent, { children: "\uC608\uC57D\uAE08 \uC788\uC74C", type: "subtitle3" }))),
        React.createElement(HookFormInputs.SelectInput, { label: "\uC608\uC57D\uB4F1\uB85D\uC2DC \uAE30\uBCF8 \uC608\uC57D\uC0C1\uD0DC*", controlProps: {
                name: "defaultStatus",
                control: form.control
            }, inputProps: {
                style: { width: '300px' },
                placeholder: '사용안함',
                options: [
                    { value: 'pending', label: '예약대기' },
                    { value: 'confirm', label: '예약확정' }
                ]
            } }),
        React.createElement(HookFormInputs.SelectInput, { label: "\uC608\uC57D\uB300\uAE30\uB85C \uB4F1\uB85D\uD558\uB294 \uACE0\uAC1D", controlProps: {
                name: "pendingCustomersSegmentFields",
                control: form.control
            }, inputProps: {
                style: { width: '300px' },
                placeholder: '사용안함',
                mode: 'multiple',
                options: segments === null || segments === void 0 ? void 0 : segments.map(function (item) {
                    return {
                        value: item.id,
                        label: item.name
                    };
                })
            } }),
        React.createElement(FloatingButtonGroup, { visible: form.formState.isDirty, confirmButtonProps: {
                onClick: function () {
                    onSubmit();
                }
            }, cancelButtonProps: {
                onClick: function () {
                    form.reset(defaultValues);
                }
            } })));
};
var DepositMessageVariableSetting = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var store = props.store;
    var operationPreference = store.operationPreference;
    var defaultValues = {
        paymentInfo: store.paymentInfo || {},
        depositTimeLimit: operationPreference.depositTimeLimit || 120
    };
    var fields = StoreAccountQuery.getRegisterAccountFields({ storeId: (_a = props.store) === null || _a === void 0 ? void 0 : _a.id }, { enabled: !!((_b = props.store) === null || _b === void 0 ? void 0 : _b.id) });
    var isScrap = (_c = store === null || store === void 0 ? void 0 : store.paymentInfo) === null || _c === void 0 ? void 0 : _c.isScrap;
    var disabled = isScrap ? !((_d = store === null || store === void 0 ? void 0 : store.paymentInfo) === null || _d === void 0 ? void 0 : _d.isEditable) : false;
    var form = useForm({ defaultValues: defaultValues });
    var update = StoreQuery.useUpdateStore();
    var banks = ((_e = store === null || store === void 0 ? void 0 : store.paymentInfo) === null || _e === void 0 ? void 0 : _e.isScrap)
        ? (_g = (_f = fields === null || fields === void 0 ? void 0 : fields.data) === null || _f === void 0 ? void 0 : _f.filter(function (item) { return item.code !== '06'; })) === null || _g === void 0 ? void 0 : _g.map(function (item) {
            return {
                label: item.name,
                value: item.code
            };
        })
        : Object.keys(BANKS).map(function (key) { return ({ label: BANKS[key], value: key }); });
    var onSubmit = form.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var paymentInfo, rest, newPreference, error_4;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    paymentInfo = values.paymentInfo, rest = __rest(values, ["paymentInfo"]);
                    newPreference = __assign(__assign({}, store.preference), { operation: __assign(__assign(__assign({}, store.operationPreference), (_a = store.preference) === null || _a === void 0 ? void 0 : _a.operation), { depositTimeLimit: rest.depositTimeLimit }) });
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, update.mutateAsync({
                            id: props.store.id,
                            paymentInfo: isScrap
                                ? paymentInfo
                                : __assign(__assign({}, paymentInfo), { bankName: BANKS[paymentInfo.code] }),
                            preference: newPreference
                        })];
                case 2:
                    _b.sent();
                    notification.success({ message: '설정이 수정되었습니다.' });
                    return [3 /*break*/, 4];
                case 3:
                    error_4 = _b.sent();
                    notification.error({ message: error_4.message || '설정중 에러가 발생했습니다.' });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, function (errors) {
        console.log('onSubmit errors', errors);
    });
    React.useEffect(function () {
        form.reset(defaultValues);
    }, [props.store]);
    return (React.createElement(DepositAmountSettingForm, null,
        React.createElement(HookFormInputs.SelectInput, { label: "\uC608\uC57D\uAE08 \uC785\uAE08 \uAE30\uD55C", controlProps: {
                name: "depositTimeLimit",
                control: form.control
            }, inputProps: {
                style: { width: '300px' },
                placeholder: '사용안함',
                options: [
                    { value: 10, label: '10분' },
                    { value: 20, label: '20분' },
                    { value: 30, label: '30분' },
                    { value: 40, label: '40분' },
                    { value: 50, label: '50분' },
                    { value: 60, label: '1시간' },
                    { value: 120, label: '2시간' },
                    { value: 180, label: '3시간' }
                ]
            } }),
        React.createElement(Layout.Columns, { gutter: 8, columns: 4, style: { maxWidth: '431px' }, switchAt: 430 },
            React.createElement("div", null,
                React.createElement(HookFormInputs.SelectInput, { label: "\uC740\uD589\uBA85", controlProps: {
                        name: "paymentInfo.code",
                        control: form.control,
                        rules: {
                            required: { value: true, message: '은행을 선택해주세요.' }
                        }
                    }, inputProps: {
                        disabled: disabled,
                        placeholder: '사용안함',
                        options: banks,
                        style: { width: '150px' }
                    } })),
            React.createElement(Layout.Column, { span: 2 },
                React.createElement(HookFormInputs.Text, { label: "\uACC4\uC88C\uBC88\uD638", controlProps: {
                        name: "paymentInfo.accountNumber",
                        control: form.control,
                        rules: {
                            maxLength: {
                                value: 30,
                                message: '올바른 계좌번호를 입력해주세요.'
                            },
                            pattern: {
                                value: /\d{5,30}/,
                                message: '올바른 계좌번호를 입력해주세요.'
                            }
                        }
                    }, inputProps: {
                        disabled: disabled,
                        placeholder: '계좌번호'
                    } })),
            React.createElement("div", null,
                React.createElement(HookFormInputs.Text, { label: "\uC608\uAE08\uC8FC\uBA85", controlProps: {
                        name: "paymentInfo.owner",
                        control: form.control,
                        rules: {
                            required: { value: true, message: '예금주를 입력해주세요.' }
                        }
                    }, inputProps: {
                        disabled: disabled,
                        placeholder: '예금주명'
                    } }))),
        React.createElement(FloatingButtonGroup, { visible: form.formState.isDirty, confirmButtonProps: {
                onClick: function () {
                    onSubmit();
                }
            }, cancelButtonProps: {
                onClick: function () {
                    form.reset(defaultValues);
                }
            } })));
};
var DepositAmountSettingForm = styled.form(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  & > * {\n    margin-bottom: 10px;\n  }\n"], ["\n  & > * {\n    margin-bottom: 10px;\n  }\n"])));
var StyledCardTabs = styled(CardWithTabs)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  & .ant-card-body {\n    padding: 0px;\n    width: 100%;\n    height: 100%;\n  }\n"], ["\n  & .ant-card-body {\n    padding: 0px;\n    width: 100%;\n    height: 100%;\n  }\n"])));
var CalendarDisplaySettings = function (props) {
    var _a = React.useState('default'), activeTab = _a[0], setActiveTab = _a[1];
    var handleTabChange = function (tab) {
        setActiveTab(tab);
    };
    var tabs = [
        { tab: '예약캘린더', key: 'default' },
        { tab: '예약블록', key: 'block' }
    ];
    return (React.createElement(StyledCardTabs, { activeTabKey: activeTab, onTabChange: handleTabChange, tabList: tabs, children: activeTab === 'default' ? (React.createElement(CalenderDisplaySetting, { store: props.store })) : (React.createElement(ScheduleBlockSetting, { store: props.store })) }));
};
var CalenderDisplaySetting = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var store = props.store;
    var _m = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _m[1];
    var form = Form.useForm()[0];
    var initialValues = {
        breakTime: (_a = store === null || store === void 0 ? void 0 : store.operationPreference) === null || _a === void 0 ? void 0 : _a.breakTime,
        defaultStatus: (_b = store === null || store === void 0 ? void 0 : store.operationPreference) === null || _b === void 0 ? void 0 : _b.defaultStatus,
        operatingHour: (_c = store === null || store === void 0 ? void 0 : store.operationPreference) === null || _c === void 0 ? void 0 : _c.operatingHour,
        saleDisplay: (_d = store === null || store === void 0 ? void 0 : store.operationPreference) === null || _d === void 0 ? void 0 : _d.saleDisplay,
        timeStep: (_e = store === null || store === void 0 ? void 0 : store.operationPreference) === null || _e === void 0 ? void 0 : _e.timeStep,
        timeStepInMobile: ((_f = store === null || store === void 0 ? void 0 : store.operationPreference) === null || _f === void 0 ? void 0 : _f.timeStepInMobile) || 30,
        simpleScheduleCreate: ((_g = store === null || store === void 0 ? void 0 : store.operationPreference) === null || _g === void 0 ? void 0 : _g.simpleScheduleCreate) || 'active'
    };
    var loader = useEntireLoader();
    var isChanged = !((_h = store === null || store === void 0 ? void 0 : store.preference) === null || _h === void 0 ? void 0 : _h.operation) || (!isEqual(initialValues, form.getFieldsValue()) && form.isFieldsTouched());
    var handleReset = function () {
        form.resetFields();
        setReservationPreference(initialValues);
        forceUpdate();
    };
    React.useEffect(function () {
        handleReset();
    }, [props.store]);
    var _o = React.useState(initialValues), reservationPreference = _o[0], setReservationPreference = _o[1];
    var updateStore = StoreQuery.useUpdateStore();
    var handleSubmit = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var today_1, err_1, error_5;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    loader.show();
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 8, 9, 10]);
                    return [4 /*yield*/, updateStore.mutateAsync({
                            id: store.id,
                            preference: __assign(__assign({}, store.preference), { operation: __assign(__assign(__assign({}, store.operationPreference), (_a = store === null || store === void 0 ? void 0 : store.preference) === null || _a === void 0 ? void 0 : _a.operation), values) })
                        })];
                case 2:
                    _b.sent();
                    if (!store.isOfficialNaver) return [3 /*break*/, 7];
                    _b.label = 3;
                case 3:
                    _b.trys.push([3, 6, , 7]);
                    return [4 /*yield*/, LambdaClient.getInstance().remove("/naver/store-cache/".concat(store.id))];
                case 4:
                    _b.sent();
                    today_1 = dayjs();
                    return [4 /*yield*/, syncNaverCalendar({
                            store: store,
                            effectedDates: times(30).map(function (day) { return today_1.add(day, 'day').toDate(); })
                        }, true)];
                case 5:
                    _b.sent();
                    return [3 /*break*/, 7];
                case 6:
                    err_1 = _b.sent();
                    loader.hide();
                    return [3 /*break*/, 7];
                case 7:
                    notification.success({ message: '예약캘린더 설정이 변경되었습니다.' });
                    return [3 /*break*/, 10];
                case 8:
                    error_5 = _b.sent();
                    notification.error({ message: error_5.message });
                    return [3 /*break*/, 10];
                case 9:
                    loader.hide();
                    return [7 /*endfinally*/];
                case 10: return [2 /*return*/];
            }
        });
    }); };
    var isPC = useWindowSize().isPC;
    return (React.createElement("div", { style: { padding: '20px' } },
        React.createElement(Row, null,
            React.createElement(Col, { span: isPC ? 12 : 24 },
                React.createElement(AntdForm, { formProps: {
                        layout: 'vertical',
                        form: form,
                        initialValues: initialValues,
                        onFinish: handleSubmit,
                        onValuesChange: function (_, values) {
                            setReservationPreference(values);
                        }
                    }, onSubmit: handleSubmit, fields: [
                        {
                            element: (React.createElement(EverydayTimeSetter, { timeStep: (_j = props.store) === null || _j === void 0 ? void 0 : _j.timeStep, operatingHour: reservationPreference.operatingHour, onChange: function (operatingHour) {
                                    var newFieldsValue = __assign(__assign({}, form.getFieldsValue()), { operatingHour: operatingHour });
                                    form.setFieldsValue({ operatingHour: operatingHour });
                                    setReservationPreference(newFieldsValue);
                                } }))
                        },
                        { element: React.createElement("div", { style: { height: '20px' } }) },
                        {
                            type: 'radio',
                            itemProps: {
                                name: 'timeStep',
                                label: '예약시간 간격',
                                rules: [{ type: 'number', required: true }]
                            },
                            inputProps: {
                                options: [
                                    { label: '10분', value: 10 },
                                    { label: '30분', value: 30 }
                                ]
                            }
                        },
                        {
                            type: 'radio',
                            itemProps: {
                                name: 'timeStepInMobile',
                                label: '모바일 시간 간격 표시',
                                rules: [{ type: 'number', required: true }],
                                style: { width: '500px' }
                            },
                            inputProps: {
                                options: [
                                    { label: '30분', value: 30 },
                                    { label: '60분', value: 60 }
                                ]
                            }
                        },
                        // {
                        //   type: 'radio',
                        //   itemProps: {
                        //     name: 'showUnassigned',
                        //     label: '담당자 없음 캘린더 노출 여부',
                        //     rules: [{ type: 'boolean', required: true }]
                        //   },
                        //   inputProps: {
                        //     options: [
                        //       { label: '노출', value: true },
                        //       { label: '비노출', value: false }
                        //     ]
                        //   }
                        // },
                        {
                            type: 'radio',
                            itemProps: {
                                name: ['breakTime', 'isActive'],
                                label: '점심시간',
                                rules: [{ type: 'boolean', required: true }]
                            },
                            inputProps: {
                                options: [
                                    { label: '설정', value: true },
                                    { label: '비설정', value: false }
                                ],
                                onChange: function (ev) {
                                    var prevBreakTime = form.getFieldValue(['breakTime']);
                                    var newBreaktime = __assign(__assign({}, prevBreakTime), { isActive: ev.target.value });
                                    form.setFieldsValue({ breakTime: newBreaktime });
                                    var newFieldsValue = __assign(__assign({}, form.getFieldsValue()), { breakTime: newBreaktime });
                                    setReservationPreference(newFieldsValue);
                                }
                            }
                        },
                        ((_k = reservationPreference === null || reservationPreference === void 0 ? void 0 : reservationPreference.breakTime) === null || _k === void 0 ? void 0 : _k.isActive) && {
                            element: (React.createElement(StyledRangeInput, { timePickerProps: { allowClear: false, minuteStep: (_l = props.store) === null || _l === void 0 ? void 0 : _l.timeStep }, data: reservationPreference === null || reservationPreference === void 0 ? void 0 : reservationPreference.breakTime, onChange: function (breakTime) {
                                    var prevBreakTime = form.getFieldValue(['breakTime']);
                                    var newBreaktime = __assign(__assign({}, prevBreakTime), breakTime);
                                    form.setFieldsValue({ breakTime: newBreaktime });
                                    var newFieldsValue = __assign(__assign({}, form.getFieldsValue()), { breakTime: newBreaktime });
                                    setReservationPreference(newFieldsValue);
                                } }))
                        },
                        { element: React.createElement("div", { style: { height: '10px' } }) },
                        {
                            type: 'select',
                            itemProps: {
                                label: '실 매출액 표시',
                                name: 'saleDisplay'
                            },
                            inputProps: {
                                options: [
                                    { value: 'all', label: '실 매출액 노출' },
                                    { value: 'button', label: '매출보기 버튼만 노출' },
                                    { value: 'hide', label: '숨기기' }
                                ]
                            }
                        },
                        { element: React.createElement("div", { style: { height: '10px' } }) },
                        {
                            type: 'radio',
                            itemProps: {
                                name: 'simpleScheduleCreate',
                                label: '간단 일정 기능',
                                rules: [{ type: 'string', required: true }]
                            },
                            inputProps: {
                                options: [
                                    { label: '활성', value: 'active' },
                                    { label: '비활성', value: 'inactive' },
                                    { label: '확인알림', value: 'confirm' }
                                ]
                            }
                        },
                        {
                            type: 'input',
                            itemProps: {
                                label: '',
                                name: 'breakTime',
                                // TODO: 유효성 검증 어케하죠??
                                rules: [
                                    function (_a) {
                                        var getFieldValue = _a.getFieldValue;
                                        return ({
                                            validator: function (rule, operatingHour) {
                                                if (Lodash.isEmpty(operatingHour)) {
                                                    return Promise.reject('요일을 하나 이상 선택해주세요.');
                                                }
                                                var isTimeValid = true;
                                                return isTimeValid ? Promise.resolve() : Promise.reject('시간은 공란일 수 없습니다.');
                                            }
                                        });
                                    }
                                ]
                            },
                            inputProps: {}
                        },
                        {
                            type: 'text',
                            itemProps: {
                                label: '',
                                name: 'operatingHour'
                                // TODO: 유효성 검증 어케하죠??
                            },
                            inputProps: {
                                hidden: true
                            }
                        }
                    ].filter(Boolean) }))),
        React.createElement(FloatingButtonGroup, { visible: isChanged, confirmButtonProps: {
                onClick: form.submit
            }, cancelButtonProps: {
                onClick: function () {
                    handleReset();
                }
            } })));
};
var ScheduleBlockSetting = function (props) {
    var store = props.store;
    var fields = store.scheduleBlockSettings;
    var methods = useForm();
    var updateStore = StoreQuery.useUpdateStore();
    var _a = React.useState(), setting = _a[0], setSetting = _a[1];
    React.useEffect(function () {
        if (setting) {
            methods.reset(__assign(__assign({}, setting), { name: (setting === null || setting === void 0 ? void 0 : setting.type) === 'scheduleProduct' ? '시술내역' : setting.type === 'description' ? '메모' : '고객세그먼트' }));
        }
    }, [setting]);
    var handleOrder = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var error_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, updateStore.mutateAsync({
                            id: store.id,
                            preference: __assign(__assign({}, store.preference), { scheduleBlockSettings: values })
                        })];
                case 1:
                    _a.sent();
                    notification.success({ message: '예약블록 설정이 변경되었습니다.' });
                    return [3 /*break*/, 3];
                case 2:
                    error_6 = _a.sent();
                    notification.error({ message: error_6.message });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var updateSetting = function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var error_7;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, updateStore.mutateAsync({
                            id: store.id,
                            preference: __assign(__assign({}, store.preference), { scheduleBlockSettings: values })
                        })];
                case 1:
                    _a.sent();
                    notification.success({ message: '예약블록 설정이 변경되었습니다.' });
                    return [3 /*break*/, 3];
                case 2:
                    error_7 = _a.sent();
                    notification.error({ message: error_7.message });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var active = methods.watch('active');
    var type = methods.watch('type');
    var handClose = function () {
        setSetting(undefined);
    };
    var onSubmit = methods.handleSubmit(function (values) { return __awaiter(void 0, void 0, void 0, function () {
        var newSettings;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    newSettings = fields.map(function (item) {
                        if (item.type === values.type) {
                            return {
                                type: values.type,
                                active: values.active
                            };
                        }
                        else {
                            return item;
                        }
                    });
                    return [4 /*yield*/, updateSetting(newSettings)];
                case 1:
                    _a.sent();
                    setSetting(null);
                    return [2 /*return*/];
            }
        });
    }); });
    return (React.createElement("div", { style: { padding: '20px' } },
        React.createElement(SortableTables, { tables: ['storeBlockElements'].map(function (type, index) {
                return {
                    bordered: false,
                    onRow: function (record) { return ({
                        onClick: function () {
                            setSetting(record);
                        }
                    }); },
                    id: index,
                    columns: [
                        {
                            title: '예약정보',
                            dataIndex: 'type',
                            render: function (_) {
                                return _ === 'scheduleProduct' ? '예약상품' : _ === 'description' ? '예약메모' : '고객 세그먼트';
                            }
                        },
                        {
                            title: '노출여부',
                            dataIndex: 'active',
                            key: 'active',
                            render: function (_, record, index) {
                                return _ ? '노출' : '미노출';
                            }
                        }
                    ],
                    onSortEnd: handleOrder,
                    dataSource: (fields || []).map(function (item, index) { return (__assign(__assign({}, item), { key: index, index: index, id: index })); })
                };
            }) }),
        React.createElement(DrawerComponent, { title: "\uC608\uC57D\uBE14\uB85D \uB178\uCD9C\uC124\uC815", open: !!setting, onClose: handClose, footer: React.createElement(DrawerFooter, { onCancel: handClose, onConfirm: onSubmit, confirmText: "\uC800\uC7A5" }) },
            React.createElement(StyledForm, null,
                React.createElement(HookFormInputs.Text, { controlProps: {
                        name: "name",
                        control: methods.control,
                        rules: {
                            required: true
                        }
                    }, label: "\uC608\uC57D\uC815\uBCF4", isHorizontal: true, inputProps: {
                        size: 'large',
                        disabled: true
                    } }),
                React.createElement(HookFormInputs.SwitchInput, { isHorizontal: true, inputProps: {
                        checked: !!active,
                        onChange: function (e) {
                            methods.setValue('active', e.target.value);
                        }
                    }, label: "\uB178\uCD9C \uC124\uC815", controlProps: {
                        name: "active",
                        control: methods.control
                    } }),
                React.createElement(HookFormInputs.Text, { controlProps: {
                        name: "type",
                        control: methods.control,
                        rules: {
                            required: true
                        }
                    }, isHorizontal: true, inputProps: {
                        hidden: true,
                        size: 'large',
                        disabled: true
                    } })))));
};
var StyledForm = styled.form(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  & > * {\n    margin-bottom: 20px;\n  }\n"], ["\n  & > * {\n    margin-bottom: 20px;\n  }\n"])));
var StyledRangeInput = styled(TimeRangeInputs)(templateObject_8 || (templateObject_8 = __makeTemplateObject([""], [""])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
