import React from 'react';
import { SERVICE_STAGING } from 'renderer/constants';
import { flatten, getDocumentReference } from 'renderer/utils';
import { onSnapshot } from '@firebase/firestore';
import { QUERY_CACHE } from 'renderer/queries';
import { ScheduleModel } from 'renderer/models';
import { Timestamp } from 'firebase/firestore';
// 초단위 (30초)
var UPDATE_THRESHOLD = 30;
export var DataUpdateChecker = function (props) {
    var handleSnapshot = function (snapshot) {
        var _a, _b;
        try {
            var newDataFromFirebase = snapshot.data();
            var now = Timestamp.now();
            // UPDATE_THRESHOLD초 전 데이터는 무시 (오래된 데이터 무시)
            if (!newDataFromFirebase || ((_a = newDataFromFirebase.updatedAt) === null || _a === void 0 ? void 0 : _a.seconds) <= now.seconds - UPDATE_THRESHOLD) {
                return;
            }
            if (newDataFromFirebase.type === 'schedule') {
                invalidQuery(['schedules'], newDataFromFirebase);
                if (((_b = newDataFromFirebase.data) === null || _b === void 0 ? void 0 : _b.type) === ScheduleModel.RESERVATION_TYPE.RESERVATION) {
                    invalidQuery(['pendingSchedules'], newDataFromFirebase);
                    invalidQuery(['schedulesByCustomerId'], newDataFromFirebase);
                }
            }
            else if (newDataFromFirebase.type === 'sale') {
            }
        }
        catch (err) {
            console.log('err', err);
        }
    };
    React.useEffect(function () {
        try {
            var docRef = getDocumentReference("lastUpdate-".concat(SERVICE_STAGING), "".concat(props.storeId));
            var unsubscribe_1 = onSnapshot(docRef, handleSnapshot, function (error) { });
            return function () { return unsubscribe_1(); };
        }
        catch (err) { }
    }, [props.storeId]);
    return React.createElement("div", null);
};
var invalidQuery = function (queryKey, newDataFromFirebase) {
    var _a, _b, _c;
    var allSchedules = flatten(((_b = (_a = QUERY_CACHE.getQueriesData(queryKey)) === null || _a === void 0 ? void 0 : _a.filter(function (_a) {
        var _ = _a[0], data = _a[1];
        return Array.isArray(data);
    })) === null || _b === void 0 ? void 0 : _b.map(function (_a) {
        var _ = _a[0], data = _a[1];
        return data;
    })) || []);
    var lastUpdatedItem = allSchedules.reduce(function (latest, item) {
        if (!(item === null || item === void 0 ? void 0 : item.updatedAt))
            return latest;
        if (!(latest === null || latest === void 0 ? void 0 : latest.updatedAt))
            return item;
        return new Date(latest.updatedAt) > new Date(item.updatedAt) ? latest : item;
    }, null);
    var shouldInvalidate = !(lastUpdatedItem === null || lastUpdatedItem === void 0 ? void 0 : lastUpdatedItem.updatedAt) ||
        Timestamp.fromDate(new Date(lastUpdatedItem.updatedAt)).seconds < ((_c = newDataFromFirebase === null || newDataFromFirebase === void 0 ? void 0 : newDataFromFirebase.updatedAt) === null || _c === void 0 ? void 0 : _c.seconds);
    if (shouldInvalidate) {
        QUERY_CACHE.invalidateQueries(queryKey);
    }
};
