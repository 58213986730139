var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useRecoilState } from 'recoil';
import { QUERY_CACHE } from 'renderer/queries';
import { Atoms } from 'renderer/stores';
import { getDocument, getFCMToken, isPlussharpMobileApp, LocalStorage, RestClient, SessionStorage, TagManager, WebviewInterface, ThinkofyouCookie } from 'renderer/utils';
import { LOCAL_STORAGE_KEY_ACCESS_TOKEN, SESSION_STORAGE_KEY_IS_SUPERADMIN_LOGIN, API_STAGE, PATH_SIGNIN, SESSION_STORAGE_KEY_REFRESH_TOKEN, SESSION_STORAGE_KEY_ACCESS_TOKEN, LOCAL_STORAGE_KEY_REFRESH_TOKEN } from 'renderer/constants';
import { getMeWithoutStoreIdInCache } from 'renderer/queries/manager';
import { setDocument } from 'renderer/utils/firebase';
import { useNavigate } from 'react-router';
import { deleteDoc } from '@firebase/firestore';
import { noop } from 'lodash';
import { setUser as setSentryUser } from '@sentry/react';
export var useAccessToken = function () {
    var navigate = useNavigate();
    var _a = useRecoilState(Atoms.accessTokenState), accessToken = _a[0], setAccessToken = _a[1];
    var _b = useRecoilState(Atoms.refreshTokenState), refreshToken = _b[0], setRefreshToken = _b[1];
    var isAutoLogin = (ThinkofyouCookie.getInstance().get('refresh_token') ||
        LocalStorage.set(LOCAL_STORAGE_KEY_REFRESH_TOKEN, refreshToken)) &&
        LocalStorage.get(LOCAL_STORAGE_KEY_ACCESS_TOKEN) &&
        !SessionStorage.get(SESSION_STORAGE_KEY_IS_SUPERADMIN_LOGIN);
    var updateAccessToken = function (token, persistLevel) {
        setAccessToken(token);
        if (persistLevel === 'local') {
            LocalStorage.set(LOCAL_STORAGE_KEY_ACCESS_TOKEN, token);
        }
        else if (persistLevel === 'session') {
            SessionStorage.set(SESSION_STORAGE_KEY_ACCESS_TOKEN, token);
        }
    };
    var updateRefreshToken = function (token, persistLevel) {
        setRefreshToken(token);
        if (persistLevel === 'local') {
            ThinkofyouCookie.getInstance().remove('refresh_token');
            ThinkofyouCookie.getInstance().set('refresh_token', refreshToken);
            LocalStorage.set(LOCAL_STORAGE_KEY_REFRESH_TOKEN, refreshToken);
        }
        else if (persistLevel === 'session') {
            SessionStorage.set(SESSION_STORAGE_KEY_REFRESH_TOKEN, token);
        }
    };
    var updateToken = function (accessToken, refreshToken, persistLevel) {
        if (accessToken) {
            updateAccessToken(accessToken, persistLevel);
        }
        if (refreshToken) {
            updateRefreshToken(refreshToken, persistLevel);
        }
    };
    var signOut = function (shouldNavigate) {
        var _a;
        var data = getMeWithoutStoreIdInCache();
        var managerId = data === null || data === void 0 ? void 0 : data.manager.id;
        var storeId = data === null || data === void 0 ? void 0 : data.manager.storeId;
        var deviceId = LocalStorage.getDeviceUnique();
        // 푸시 토큰 삭제해야
        if (isPlussharpMobileApp()) {
            (_a = WebviewInterface.getInstance()) === null || _a === void 0 ? void 0 : _a.requestSignout({
                managerId: managerId,
                storeId: storeId,
                apiStage: API_STAGE
            });
        }
        else {
            if (managerId && storeId) {
                getFCMToken()
                    .then(function (token) { return __awaiter(void 0, void 0, void 0, function () {
                    var doc;
                    return __generator(this, function (_a) {
                        doc = getDocument("managers-".concat(API_STAGE), "".concat(managerId));
                        doc.then(function (data) {
                            if (data.exists) {
                                var tokens = ((data.data() && data.data().tokens) || []).filter(function (target) { return (target === null || target === void 0 ? void 0 : target.token) !== token; }) || [];
                                setDocument(data.ref, {
                                    managerId: managerId,
                                    storeId: storeId,
                                    tokens: tokens
                                });
                            }
                        });
                        return [2 /*return*/];
                    });
                }); })
                    .catch(function (err) { return console.log(err); });
            }
        }
        if (managerId) {
            RestClient.getInstance().get("/manager/signout?managerId=".concat(managerId, "&deviceId=").concat(deviceId)).catch(noop);
        }
        clearAllData();
        if (shouldNavigate) {
            navigate(PATH_SIGNIN, { replace: true });
        }
    };
    var clearAllData = function () {
        QUERY_CACHE.clear();
        RestClient.getInstance().setAccessToken(null);
        LocalStorage.removeItem(LOCAL_STORAGE_KEY_ACCESS_TOKEN);
        LocalStorage.removeItem(LOCAL_STORAGE_KEY_REFRESH_TOKEN);
        SessionStorage.removeItem(SESSION_STORAGE_KEY_REFRESH_TOKEN);
        SessionStorage.removeItem(SESSION_STORAGE_KEY_ACCESS_TOKEN);
        SessionStorage.removeItem(SESSION_STORAGE_KEY_IS_SUPERADMIN_LOGIN);
        ThinkofyouCookie.getInstance().remove('refresh_token');
        TagManager.Instance.dataLayer({
            dataLayer: {
                userId: null
            }
        });
        setSentryUser({
            id: null,
        });
    };
    var signOutFromAllDevice = function (managerId, isMe) { return __awaiter(void 0, void 0, void 0, function () {
        var doc, ref;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, RestClient.getInstance().get("/manager/signout?managerId=".concat(managerId))];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, getDocument("managers-".concat(API_STAGE), "".concat(managerId))];
                case 2:
                    doc = _a.sent();
                    ref = doc.ref;
                    deleteDoc(ref);
                    if (isMe) {
                        clearAllData();
                        navigate(PATH_SIGNIN, { replace: true });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return {
        isAutoLogin: isAutoLogin,
        accessToken: accessToken,
        refreshToken: refreshToken,
        updateAccessToken: updateAccessToken,
        updateRefreshToken: updateRefreshToken,
        updateToken: updateToken,
        signOut: signOut,
        signOutFromAllDevice: signOutFromAllDevice
    };
};
