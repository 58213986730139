var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { StoreCourseModel } from 'renderer/models';
import React from 'react';
import { ChanneltalkDisplayNone, DrawerComponent, Icon, TextComponent, FloatingPopup } from 'renderer/components';
import { Atoms } from 'renderer/stores';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { COLORS } from 'renderer/styles';
import { Button, Card, Col, FloatButton, List, notification, Progress, Row } from 'antd/lib';
import { StoreCourseQuery, StoreQuery } from 'renderer/queries';
import { useSpring, animated, config } from 'react-spring';
import { IMAGES } from 'renderer/assets';
var PC_WIDTH = '720px';
var MOBILE_WIDTH = '100%';
var TITLE_Z_INDEX = 1000;
export var CourseMenu = function (props) {
    var _a;
    var _b = useRecoilState(Atoms.courseDrawerState), courseDrawer = _b[0], setCourseDrawer = _b[1];
    var isPC = props.isPC;
    var courses = StoreCourseQuery.useGetCourses({ storeId: (_a = props.store) === null || _a === void 0 ? void 0 : _a.id }).data;
    var addProgress = StoreCourseQuery.useAddStoreCourseProgress();
    var handleClick = function (type, item) { return __awaiter(void 0, void 0, void 0, function () {
        var payload, payload, title, err_1;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (!(type === 'watch')) return [3 /*break*/, 1];
                    setCourseDrawer(__assign(__assign({}, courseDrawer), { lastCourseId: item.id }));
                    if (!((_a = item.progress) === null || _a === void 0 ? void 0 : _a.watchedAt)) {
                        payload = {
                            storeId: (_b = props.store) === null || _b === void 0 ? void 0 : _b.id,
                            storeCourseId: item.id,
                            watchedAt: new Date()
                        };
                        addProgress.mutate(payload);
                    }
                    return [3 /*break*/, 9];
                case 1:
                    if (!(type === 'close')) return [3 /*break*/, 2];
                    setCourseDrawer(__assign(__assign({}, courseDrawer), { status: 'off' }));
                    return [3 /*break*/, 9];
                case 2:
                    if (!(type === 'back')) return [3 /*break*/, 3];
                    setCourseDrawer(__assign(__assign({}, courseDrawer), { lastCourseId: null }));
                    return [3 /*break*/, 9];
                case 3:
                    if (!(type === 'open')) return [3 /*break*/, 4];
                    setCourseDrawer(__assign(__assign({}, courseDrawer), { status: 'on' }));
                    return [3 /*break*/, 9];
                case 4:
                    if (!(type === 'float')) return [3 /*break*/, 5];
                    setCourseDrawer(__assign(__assign({}, courseDrawer), { status: 'float' }));
                    return [3 /*break*/, 9];
                case 5:
                    if (!(type === 'reward')) return [3 /*break*/, 9];
                    payload = {
                        storeId: (_c = props.store) === null || _c === void 0 ? void 0 : _c.id,
                        storeCourseId: item.id,
                        rewardedAt: new Date()
                    };
                    title = item.rewardType === 'POINT' ? '포인트 받기' : '체험기간 받기';
                    if (!((_d = item.progress) === null || _d === void 0 ? void 0 : _d.watchedAt)) {
                        return [2 /*return*/, notification.error({
                                placement: 'topLeft',
                                message: "".concat(title, " \uC2E4\uD328"),
                                description: "\uAD50\uC721 \uC2DC\uCCAD \uD6C4 \uBBF8\uC158\uC744 \uC644\uC218\uD574\uC8FC\uC138\uC694 "
                            })];
                    }
                    _e.label = 6;
                case 6:
                    _e.trys.push([6, 8, , 9]);
                    return [4 /*yield*/, addProgress.mutateAsync(payload)];
                case 7:
                    _e.sent();
                    notification.success({
                        placement: 'topLeft',
                        message: "".concat(title, " \uC131\uACF5"),
                        description: "".concat(item.rewardValue, " ").concat(item.rewardType === 'POINT' ? 'P' : ' 일', " \uC218\uB839\uC774 \uC644\uB8CC\uB418\uC5C8\uC2B5\uB2C8\uB2E4.")
                    });
                    return [3 /*break*/, 9];
                case 8:
                    err_1 = _e.sent();
                    notification.error({
                        placement: 'topLeft',
                        message: "".concat(title, " \uC2E4\uD328"),
                        description: "".concat(err_1.message)
                    });
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }); };
    var lastCourse = courses === null || courses === void 0 ? void 0 : courses.find(function (course) { return course.id === courseDrawer.lastCourseId; });
    var floatingButtonAnimation = useSpring({
        transform: courseDrawer.status === 'float' ? 'translate(0, 0) scale(1)' : 'translate(360px, -300px) scale(5)',
        opacity: courseDrawer.status === 'float' ? 1 : 0,
        config: __assign(__assign({}, config.wobbly), { duration: 300 })
    });
    var width = props.isPC ? PC_WIDTH : MOBILE_WIDTH;
    switch (courseDrawer.status) {
        case 'off':
            return React.createElement(CourseFloatingPopup, { store: props.store, courses: courses, onClick: function () { return handleClick('open'); } });
        case 'float':
            return (React.createElement(AnimatedFloatingButtonWrapper, { style: floatingButtonAnimation, "$isPC": isPC },
                React.createElement(FloatingCourseMenu, { course: lastCourse, isPC: isPC, onClick: handleClick })));
        case 'on':
            return (React.createElement(React.Fragment, null,
                React.createElement(ChanneltalkDisplayNone, null),
                isPC ? (React.createElement(Wrapper, { "$width": width }, lastCourse ? (React.createElement(CourseContent, { course: lastCourse, isPC: isPC, onClick: handleClick })) : (React.createElement(CourseList, { courses: courses, onClick: handleClick, isPC: isPC })))) : (React.createElement(StyledDrawer, { open: true, width: '100%', closeIcon: null, title: null, children: lastCourse ? (React.createElement(CourseContent, { course: lastCourse, isPC: isPC, onClick: handleClick })) : (React.createElement(CourseList, { courses: courses, onClick: handleClick, isPC: isPC })) }))));
    }
};
var AnimatedFloatingButtonWrapper = styled(animated.div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: fixed;\n  bottom: 20px;\n  ", "\n  z-index: 1000;\n"], ["\n  position: fixed;\n  bottom: 20px;\n  ", "\n  z-index: 1000;\n"])), function (props) { return (props.$isPC ? 'right: 0px;' : 'left: 80px;'); });
var FloatingCourseMenu = function (props) {
    return (React.createElement(StyledFloatButton, { "$isLeft": props.isPC, icon: React.createElement(Icon, { name: "play" }), description: "\uB2E4\uC2DC\uBCF4\uAE30", shape: "square", onClick: function () { return props.onClick('open', props.course); }, badge: {
            count: (React.createElement(Circle, null,
                React.createElement(Icon, { name: "x", onClick: function (ev) {
                        ev.stopPropagation();
                        ev.preventDefault();
                        props.onClick('close', props.course);
                    } })))
        } }));
};
var Circle = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n  background-color: ", ";\n"], ["\n  width: 16px;\n  height: 16px;\n  border-radius: 50%;\n  background-color: ", ";\n"])), COLORS.white);
var StyledFloatButton = styled(FloatButton)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  // position: absolute;\n  // ", "\n\n  & .ant-float-btn-description {\n    font-size: 7px !important;\n  }\n"], ["\n  // position: absolute;\n  // ", "\n\n  & .ant-float-btn-description {\n    font-size: 7px !important;\n  }\n"])), function (props) { return (props.$isLeft ? 'left: 0px;' : 'left: -12px;'); });
var StyledDrawer = styled(DrawerComponent)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: ", ";\n\n  & .ant-drawer-body {\n    padding: 0;\n  }\n"], ["\n  width: ", ";\n\n  & .ant-drawer-body {\n    padding: 0;\n  }\n"])), MOBILE_WIDTH);
var CourseContent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var width = props.isPC ? PC_WIDTH : MOBILE_WIDTH;
    var url = props.isPC ? (_b = (_a = props.course) === null || _a === void 0 ? void 0 : _a.content) === null || _b === void 0 ? void 0 : _b.pc : ((_d = (_c = props.course) === null || _c === void 0 ? void 0 : _c.content) === null || _d === void 0 ? void 0 : _d.mobile) || ((_f = (_e = props.course) === null || _e === void 0 ? void 0 : _e.content) === null || _f === void 0 ? void 0 : _f.pc);
    var isArcade = ((_g = props.course) === null || _g === void 0 ? void 0 : _g.contentType) === 'arcade';
    if (isArcade) {
        if (props.isPC) {
            url = "".concat(url, "?embed&embed_mobile=inline&embed_desktop=inline&show_copy_link=false");
        }
        else {
            url = "".concat(url, "?embed&embed_mobile=inline&embed_desktop=inline&show_copy_link=false");
        }
    }
    var availableReward = ((_h = props.course) === null || _h === void 0 ? void 0 : _h.availableReward) && !((_j = props.course) === null || _j === void 0 ? void 0 : _j.isCompleted);
    var rewardButton = availableReward
        ? "".concat((_l = (_k = props.course) === null || _k === void 0 ? void 0 : _k.rewardValue) === null || _l === void 0 ? void 0 : _l.toLocaleString(), " ").concat(((_m = props.course) === null || _m === void 0 ? void 0 : _m.rewardType) === 'POINT' ? 'P' : ' 일', " \uBC1B\uAE30")
        : '';
    var iframeHeight = availableReward ? 'calc(100% - 132px)' : '100%';
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, null,
            React.createElement(Row, { align: "middle" },
                React.createElement(ClickableIcon, { name: "left-chevron", size: 24, onClick: function () { return props.onClick('back', props.course); } }),
                React.createElement(TextComponent, { children: (_o = props.course) === null || _o === void 0 ? void 0 : _o.title, type: "headline2", marginLeft: 8 })),
            React.createElement(ClickableIcon, { name: "x", size: 24, onClick: function () { return props.onClick('float', props.course); } })),
        React.createElement(IframeElementBlocker, { "$isPC": props.isPC, "$type": (_p = props.course) === null || _p === void 0 ? void 0 : _p.contentType }),
        React.createElement("iframe", { src: url, title: "\uC0C1\uD488 \uBA54\uB274 \uB4F1\uB85D", loading: "lazy", allowFullScreen: true, allow: "clipboard-write", style: { width: width, colorScheme: 'light', height: iframeHeight, border: 0, position: 'absolute', top: 0 } }),
        availableReward && (React.createElement(RewardButtonWrapper, null,
            React.createElement(Button, { type: "primary", size: "large", onClick: function () { return props.onClick('reward', props.course); } }, rewardButton)))));
};
var IframeElementBlocker = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: ", ";\n  background-color: ", ";\n  z-index: ", ";\n"], ["\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: ", ";\n  background-color: ", ";\n  z-index: ", ";\n"])), function (props) { return (props.$type === 'arcade' ? '60px' : '80px'); }, COLORS.white, TITLE_Z_INDEX - 1);
var RewardButtonWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  padding: 16px 24px;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n\n  background-color: ", ";\n\n  position: absolute;\n  bottom: 0px;\n\n  /* BoxShadowSecondary */\n  box-shadow:\n    0px 9px 28px 8px rgba(0, 0, 0, 0.05),\n    0px 3px 6px -4px rgba(0, 0, 0, 0.12),\n    0px 6px 16px 0px rgba(0, 0, 0, 0.08);\n\n  & > button {\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  padding: 16px 24px;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 8px;\n\n  background-color: ", ";\n\n  position: absolute;\n  bottom: 0px;\n\n  /* BoxShadowSecondary */\n  box-shadow:\n    0px 9px 28px 8px rgba(0, 0, 0, 0.05),\n    0px 3px 6px -4px rgba(0, 0, 0, 0.12),\n    0px 6px 16px 0px rgba(0, 0, 0, 0.08);\n\n  & > button {\n    width: 100%;\n  }\n"])), COLORS.white);
var CourseList = function (props) {
    return (React.createElement(CourseListWrapper, null,
        React.createElement(Title, null,
            React.createElement(TextComponent, { children: "\uD305\uCEE4\uBDF0 \uC0AC\uC6A9\uBC95\uAD50\uC721", type: "headline2", onClick: function () { return props.onClick('float'); } }),
            React.createElement(ClickableIcon, { name: "x", size: 24, onClick: function () { return props.onClick('float'); } })),
        React.createElement(ProgressCard, { courses: props.courses }),
        React.createElement(ScrollableContent, null,
            React.createElement(Content, null,
                React.createElement(ProgressList, { courses: props.courses, onClick: props.onClick })))));
};
var ScrollableContent = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  overflow-y: scroll;\n  flex: 1;\n"], ["\n  overflow-y: scroll;\n  flex: 1;\n"])));
var CourseListWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n"])));
var ClickableIcon = styled(Icon)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
var Wrapper = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  width: ", ";\n  min-width: ", ";\n  height: 100%;\n  flex-direction: column;\n  border-left: 1px solid ", ";\n  position: relative;\n"], ["\n  display: flex;\n  width: ", ";\n  min-width: ", ";\n  height: 100%;\n  flex-direction: column;\n  border-left: 1px solid ", ";\n  position: relative;\n"])), function (props) { return props.$width; }, function (props) { return props.$width; }, COLORS.gray5);
var Title = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  display: flex;\n  padding: 16px 24px;\n  align-items: center;\n  gap: 12px;\n  align-self: stretch;\n  border-bottom: 1px solid ", ";\n  justify-content: space-between;\n  background-color: ", ";\n  z-index: ", ";\n  position: relative;\n"], ["\n  display: flex;\n  padding: 16px 24px;\n  align-items: center;\n  gap: 12px;\n  align-self: stretch;\n  border-bottom: 1px solid ", ";\n  justify-content: space-between;\n  background-color: ", ";\n  z-index: ", ";\n  position: relative;\n"])), COLORS.gray5, COLORS.white, TITLE_Z_INDEX);
var Content = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: flex;\n  padding: 8px 20px;\n  flex-direction: column;\n  align-items: center;\n  gap: 8px;\n  flex: 1 0 0;\n  align-self: stretch;\n  background: ", ";\n  width: 100%;\n"], ["\n  display: flex;\n  padding: 8px 20px;\n  flex-direction: column;\n  align-items: center;\n  gap: 8px;\n  flex: 1 0 0;\n  align-self: stretch;\n  background: ", ";\n  width: 100%;\n"])), COLORS.gray7);
var CourseFloatingPopup = function (props) {
    var _a, _b, _c;
    var availableItem = ((_a = props.courses) === null || _a === void 0 ? void 0 : _a.filter(function (course) { return !course.isCompleted; })) || [];
    var storeUpdate = StoreQuery.useUpdateStore();
    var totalReward = availableItem.reduce(function (prevValue, course) {
        var _a, _b;
        var prevReward = prevValue[(_a = course.rewardType) === null || _a === void 0 ? void 0 : _a.toLowerCase()] || 0;
        prevValue[(_b = course.rewardType) === null || _b === void 0 ? void 0 : _b.toLowerCase()] = prevReward + course.rewardValue;
        return prevValue;
    }, {
        duration: 0,
        point: 0
    });
    var rewardString = [
        totalReward.point && "".concat(totalReward.point.toLocaleString(), " P"),
        totalReward.duration && "".concat(totalReward.duration.toLocaleString(), "\uC77C")
    ]
        .filter(Boolean)
        .join(', ');
    var handleClose = function () {
        var _a, _b;
        storeUpdate.mutate({
            id: (_a = props.store) === null || _a === void 0 ? void 0 : _a.id,
            preference: __assign(__assign({}, (_b = props.store) === null || _b === void 0 ? void 0 : _b.preference), { shouldShowLecturePopup: false })
        });
    };
    return ((_c = (_b = props.store) === null || _b === void 0 ? void 0 : _b.preference) === null || _c === void 0 ? void 0 : _c.shouldShowLecturePopup) ? (React.createElement(React.Fragment, null,
        React.createElement(FloatingPopup, { imageUrl: IMAGES.thinkofyou_course_image, title: "\uAD50\uC721\uC218\uAC15 \uD6C4 ".concat(rewardString, " \uBC1B\uAE30"), description: "\uD305\uCEE4\uBDF0 \uAD50\uC721 \uC2DC\uCCAD\uACFC \uB3C4\uC804\uACFC\uC81C\uB97C \uC644\uB8CC\uD558\uC2DC\uBA74, ".concat(rewardString, "\uC744 \uBC1B\uC73C\uC2E4 \uC218 \uC788\uC5B4\uC694."), buttonText: "\uAD50\uC721\uC601\uC0C1 \uBCF4\uACE0 \uBB34\uB8CC\uD61C\uD0DD \uBC1B\uAE30", onClose: handleClose, onButtonClick: props.onClick }),
        React.createElement(ChanneltalkDisplayNone, null))) : null;
};
var ProgressCard = function (props) {
    var _a, _b, _c;
    var availableItem = ((_a = props.courses) === null || _a === void 0 ? void 0 : _a.filter(function (course) { return !course.isCompleted && course.availableReward; })) || [];
    var completedItem = ((_b = props.courses) === null || _b === void 0 ? void 0 : _b.filter(function (course) { var _a; return course.isCompleted || ((_a = course.progress) === null || _a === void 0 ? void 0 : _a.watchedAt); })) || [];
    var totalReward = availableItem.reduce(function (prevValue, course) {
        var _a, _b;
        var prevReward = prevValue[(_a = course.rewardType) === null || _a === void 0 ? void 0 : _a.toLowerCase()] || 0;
        prevValue[(_b = course.rewardType) === null || _b === void 0 ? void 0 : _b.toLowerCase()] = prevReward + course.rewardValue;
        return prevValue;
    }, {
        duration: 0,
        point: 0
    });
    var hasReward = totalReward.duration > 0 || totalReward.point > 0;
    var totalCount = ((_c = props.courses) === null || _c === void 0 ? void 0 : _c.length) || 0;
    var completeCount = completedItem.length;
    var allCompleted = completeCount === totalCount;
    var rate = (completeCount / totalCount) * 100;
    var rewardString = [
        totalReward.point && "".concat(totalReward.point.toLocaleString(), " Point"),
        totalReward.duration && "".concat(totalReward.duration.toLocaleString(), "\uC77C")
    ]
        .filter(Boolean)
        .join(', ');
    return (React.createElement(FullWidthCard, null,
        hasReward ? (React.createElement(Row, null,
            React.createElement(TextComponent, { children: rewardString, type: "subtitle2", color: COLORS.primary1, marginRight: 4 }),
            React.createElement(TextComponent, { children: "\uB354 \uBC1B\uC73C\uC2E4 \uC218 \uC788\uC5B4\uC694", type: "body2" }))) : allCompleted ? (React.createElement(Row, null,
            React.createElement(TextComponent, { children: "\uBAA8\uB4E0 \uAD50\uC721\uC744 \uC218\uAC15\uD558\uC168\uC5B4\uC694 \uD83C\uDF89", type: "body2" }))) : (React.createElement(Row, null,
            React.createElement(TextComponent, { children: "\uC544\uC9C1 \uC218\uAC15\uD558\uC9C0 \uC54A\uC740 \uAD50\uC721\uC774 \uC788\uC5B4\uC694", type: "body2" }))),
        React.createElement(Row, { wrap: false, justify: "space-between", gutter: 4 },
            React.createElement(Col, { flex: 1 },
                React.createElement(Progress, { percent: rate, status: "active", showInfo: false })),
            React.createElement(Col, null,
                React.createElement(TextComponent, { children: "".concat(completeCount, " / ").concat(totalCount) })))));
};
var ProgressList = function (props) {
    var _a, _b;
    var beginnerCourses = ((_a = props.courses) === null || _a === void 0 ? void 0 : _a.filter(function (course) { return course.level === StoreCourseModel.STORE_COUSE_LEVELS.BEGINNER; })) || [];
    var intermediateCourses = ((_b = props.courses) === null || _b === void 0 ? void 0 : _b.filter(function (course) { return course.level === StoreCourseModel.STORE_COUSE_LEVELS.INTERMEDIATE; })) || [];
    // const advancedCourses = props.courses?.filter((course) => course.level === 'ADVANCED') || [];
    var renderCourses = function (level, courses) {
        var title = '기초설정';
        if (level === StoreCourseModel.STORE_COUSE_LEVELS.INTERMEDIATE) {
            title = '기능활용';
        }
        return (React.createElement(StyledList, { header: React.createElement(Row, { justify: "start" },
                React.createElement(TextComponent, { type: "body2", color: COLORS.gray1 }, title)), bordered: true, dataSource: courses, renderItem: function (item) { return React.createElement(ListItem, { item: item, onClick: props.onClick }); } }));
    };
    return (React.createElement(React.Fragment, null,
        renderCourses(StoreCourseModel.STORE_COUSE_LEVELS.BEGINNER, beginnerCourses),
        renderCourses(StoreCourseModel.STORE_COUSE_LEVELS.INTERMEDIATE, intermediateCourses)));
};
var RewardButton = function (props) {
    var _a, _b;
    var item = props.item;
    var unit = item.rewardType === StoreCourseModel.STORE_COUSE_REWARD_TYPES.DURATION ? '일' : 'P';
    var isWatched = (_a = item.progress) === null || _a === void 0 ? void 0 : _a.watchedAt;
    var availableReward = item.availableReward;
    var isCompleted = item.isCompleted;
    var rewardString = "".concat((_b = item.rewardValue) === null || _b === void 0 ? void 0 : _b.toLocaleString(), " ").concat(unit, " \uBC1B\uAE30");
    return availableReward ? (React.createElement(Button, { size: "small", disabled: isCompleted, type: isWatched ? 'primary' : 'default', onClick: props.onClick }, rewardString)) : null;
};
var ListItem = function (props) {
    var _a;
    var item = props.item;
    var isWatched = (_a = item.progress) === null || _a === void 0 ? void 0 : _a.watchedAt;
    return (React.createElement(List.Item, null,
        React.createElement(FullWidthRow, { justify: "space-between", align: "middle" },
            React.createElement(ClickableRow, { gutter: 16, align: "middle", onClick: function () { return props.onClick('watch', item); } },
                React.createElement(Icon, { name: isWatched ? 'check-circle' : 'circle', size: 16 }),
                React.createElement(Col, { flex: 1 },
                    React.createElement(TextComponent, { children: item.title }))),
            React.createElement(RewardButton, { item: item, onClick: function () { return props.onClick('reward', item); } }))));
};
var ClickableRow = styled(Row)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
var StyledList = styled(List)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  width: 100%;\n  background-color: ", ";\n"], ["\n  width: 100%;\n  background-color: ", ";\n"])), COLORS.white);
var FullWidthRow = styled(Row)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var FullWidthCard = styled(Card)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
